<template>
    <div class="reg_wrap">
        <div class="w_max_min reg_next_cont">
            <div class="top_logo"><router-link to="/"><img src="../../assets/img/logo.png" class="top_logoImg"
                        title="商易融" /></router-link></div>
            <div class="reg_box">
                <el-form :model="registerForm" :rules="regRules" ref="registerForm" class="reg_form">
                    <div class="reg_title m_title_b">注册信息</div>
                    <div class="err_bg" v-show="errFlag"><i class="iconfont icon-jinggao" /> &nbsp;<span>{{ errMsg }}!</span>
                    </div>
                    <el-form-item prop="cnCompany">
                        <el-autocomplete class="inline-input" placeholder="请输入公司名称" v-model="registerForm.cnCompany"
                            :fetch-suggestions="querySearch" :trigger-on-focus="true" @select="handleSelect">
                        </el-autocomplete>
                    </el-form-item>
                    <el-form-item prop="email">
                        <el-input v-model="registerForm.email" placeholder="请输入邮箱" maxlength="50"
                            @focus='errFlag = false'></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="registerForm.password" :type="type" placeholder="请输入密码" @focus='errFlag = false'>
                            <i slot="suffix" class="iconfont icon-yanjing" autocomplete="auto"
                                @click="inputType = !inputType"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input v-model="registerForm.confirmPassword" :type="type" placeholder="确认密码"
                            @focus='errFlag = false'>
                            <i slot="suffix" class="iconfont icon-yanjing" autocomplete="auto"
                                @click="inputType = !inputType"></i>
                        </el-input>
                    </el-form-item>

                    <el-form-item class="btn_box">
                        <el-button class="btn_form_reg" @click="submitRegForm('registerForm')">注&nbsp;&nbsp;册</el-button>
                    </el-form-item>
                </el-form>
                <div class="register">已有账号,<router-link
                        :to="{ name: 'Dashboard', params: { choseRole: true } }">立即登录</router-link></div>
            </div>
        </div>
    </div>
</template>
<script>
import { doLogin, getCompanyNameList, serviceRegister } from "@/api/api.js";
import formValidation from "@/utils/rule.js";
export default {
    data() {
        //两次密码一致性校验
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
            } else if (value !== this.registerForm.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                callback()
            }
        }
        return {
            //登录校验
            regRules: {
                cnCompany: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { validator: formValidation.validateEmail, trigger: "blur" }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    {
                        pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,30}$/,
                        message: '密码为数字,大小写字母,特殊符号 至少包含三种,长度为8-30位'
                    }
                ],
                confirmPassword: [
                    { required: true, validator: validatePass, trigger: 'blur' },
                ],
            },
            registerForm: {},
            errMsg: '邮箱或手机号码已注册',
            errFlag: false,//是否显示邮箱或手机号码
            roleId: '4',//服务商角色
            inputType: false,//查看密码
        }
    },
    computed: {
        type() {
            return this.inputType ? "text" : "password";
        }
    },
    methods: {
        // 供应商名称模糊搜索
        handleSelect(item) {
            this.registerForm.cnCompany = item.company_name
        },
        //获取供应商名称
        async getCompanyNameList() {
            if (this.registerForm.cnCompany) {
                let data = await getCompanyNameList('comName=' + this.registerForm.cnCompany)
                this.dataname = data.data
                if (this.dataname) {
                    this.dataname.forEach((item, index) => {
                        this.dataname[index].value = item.company_name
                    });
                }
            }
            else {
                this.dataname = []
            }
        },
        querySearch(queryString, cb) {
            this.getCompanyNameList().then(() => {
                var restaurants = this.dataname;
                var results = []

                if (restaurants) {
                    results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                    // results = queryString ? restaurants : [];
                }
                cb(results);
            })
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !==-1);
            };
        },

        //提交注册表单
        submitRegForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.register()
                } else {
                    return false;
                }
            });
        },

        //注册
        async register() {
            let res = await serviceRegister(this.registerForm)
            if (res && res.code == 200) {
                this.login()
            }
            else {
                this.errFlag = true
                this.errMsg = res.msg
                return false;
            }
        },

        //注册完需要自动登录
        async login() {
            let res = await doLogin('username=' + this.registerForm.email + '&password=' + this.registerForm.password + "&roleIds=" + this.roleId)
            if (res && res.code == 200) {
                localStorage.clear()
                let obj = res.data
                let userInfo = { userId: res.data.id, roleId: obj.roleId }
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
                localStorage.setItem('cnCompany', obj.cnCompany);

                this.$router.push({ name: 'FillInInformation', params: { userId: res.data.id } });
            }
        },
    }
}
</script>
<style scoped>
.reg_wrap {
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    min-width: 1280px;
    z-index: 0;
    zoom: 1;
    background: url('../../assets/img/reg_bg.png') no-repeat;
    background-color: #F7FAFC;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}

.reg_cont {
    width: 100%;
    padding: 220px 0 120px;
}

.reg_next_cont {
    width: 100%;
    padding: 70px 0 120px;
}

.w_max_min {
    max-width: 1600px;
    min-width: 1280px;
    margin: auto;
}

.top_logo {
    position: absolute;
    top: 20px;
}

.top_logoImg {
    height: 20px;
}

.reg_box {
    width: 550px;
    background: #FFFFFF;
    border-radius: 10px;
    margin: auto;
    padding: 40px 50px;
}

.reg_form {
    position: relative;
}

.reg_form>>>.el-autocomplete {
    width: 100%;
}

.btn_box,
.reg_form /deep/ .el-form-item:not(:last-child) {
    margin-bottom: 20px;
}

.btn_box>>>.el-form-item__content {
    text-align: center;
}

.reg_form /deep/ .el-input__inner {
    width: 100%;
    height: 50px;
    background: #F7FAFC;
    border-radius: 25px;
    border: 1px solid #D6DBE4;
    color: #54657F;
    font-size: 16px;
}

.reg_form /deep/ .el-input__inner::placeholder {
    color: #7C969B;
}

.reg_form /deep/ .icon-yanjing {
    font-size: 16px;
    color: #92A2BC;
    position: absolute;
    width: 60px;
    right: 0;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
}

.reg_form /deep/.el-input__icon.el-icon-view.el-input__clear {
    display: none;
}

.reg_form /deep/ .send_code .el-input__inner {
    width: 300px;
}

.reg_form /deep/ .el-form-item__error {
    margin-left: 30px;
}

.send_code_group /deep/ .el-form-item__content {
    display: flex;
    justify-content: space-between;
}

.btn,
.btn_form_reg {
    height: 50px;
    border-radius: 32px;
    font-size: 18px;
    color: #FFFFFF;
    border: none;
    background: #339C9B;
    width: 450px;
    box-shadow: 0px 16px 32px 0px rgba(47, 160, 159, 0.23);
    margin-top: 15px;
}

.register {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
    color: #54657F;
}

.register a {
    color: #339C9B;
}

.err_bg {
    position: absolute;
    width: 450px;
    height: 68px;
    line-height: 58px;
    font-size: 14px;
    color: #FB6476;
    text-align: center;
    background-image: url(../../assets/img/log_err_bg.png);
    background-size: 450px 66px;
    background-repeat: no-repeat;
    top: 18px;
    left: 0;
    z-index: 88;
}

.err_bg .icon-jinggao {
    font-size: 16px;
    color: #FB6476;
}

.err_txt {
    position: absolute;
    font-size: 12px;
    color: #FB6476;
    margin-left: 30px;
}

.reg_title {
    font-size: 20px;
    color: #162747;
    line-height: 26px;
    text-align: center;
}

.m_title_b {
    margin-bottom: 30px;
}

@media screen and (max-width: 1664px) {
    .reg_title {
        font-size: 13px;
        line-height: 17px;
    }

    .m_title_b {
        margin-bottom: 20px;
    }

    .reg_next_cont {
        padding: 46px 79px;
    }

    .reg_cont {
        padding: 145px 79px;
    }

    .top_logoImg {
        height: 13px;
    }

    .reg_box {
        width: 363px;
        border-radius: 7px;
        padding: 26px 20px;
    }

    .reg_form>>>.el-input__inner {
        font-size: 12px;
        height: 33px;
    }

    .reg_form>>>.send_code .el-input__inner {
        width: 198px;
    }

    .btn_box,
    .reg_form>>>.el-form-item:not(:last-child) {
        margin-bottom: 15px;
    }

    .reg_form>>>.el-form-item__error {
        margin-left: 10px;
        transform-origin: left;
        width: 102%;
    }

    .reg_form>>>.icon-yanjing {
        font-size: 10px;
        width: 40px;
        height: 33px;
        line-height: 33px;
    }


    .btn_box_next {
        width: 139px;
    }

    .btn_next {
        height: 33px;
        padding: 0;
        font-size: 12px;
    }

    .btn_box_next {
        margin: 40px auto 0;
    }

    .check {
        margin-bottom: 26px;
    }

    .check>>>.el-checkbox__inner {
        width: 15px;
        height: 15px;
    }

    .check>>>.el-checkbox__inner::after {
        width: 3px;
        height: 10px;
        left: 5px;
        top: 0;
    }

    .check>>>.el-checkbox__label {
        font-size: 12px;
    }

    .register {
        font-size: 12px;
        margin-top: 13px;
    }

    .err_bg {
        width: 323px;
        height: 45px;
        line-height: 38px;
        font-size: 12px;
        background-size: 323px 44px;
        top: 12px;
    }

    .err_bg .icon-jinggao {
        font-size: 12px;
    }

    .btn_form_reg {
        width: 323px;
        height: 33px;
        padding: 0;
        font-size: 12px;
        margin-top: 10px;
    }
}</style>